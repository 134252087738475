<template>
  <v-dialog
    v-model="isShowModal"
    width="1200px"
  >
    <v-card>
      <v-container
        grid-list-xl
        fluid
        style="padding-top: 0;">
        <v-form>
          <v-layout
            v-if="hasAction('view_user') || isAdmin() || isSuperAdmin() || isModerator() || isAccountant()"
            wrap
          >
            <v-flex
              md12
            >
              <material-card
                :title="$t('common.search')"
                color="green"
                flat
                full-width
                class="bg-white"
              >
                <v-form>
                  <v-container py-0>
                    <v-layout wrap>
                      <v-flex
                        xs12
                        md3>
                        <v-text-field
                          v-model="userSearch.searchText"
                          :label="$t('common.search')"
                          clearable
                          @keydown.enter="onSearch($event)"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        md3>
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="dateFormatted"
                              :label="$t('history.dateAction')"
                              persistent-hint
                              prepend-icon="mdi-calendar"
                              clearable
                              @blur="date = parseDate(dateFormatted)"
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="dateSearch"
                            no-title
                            @input="menu = false"
                          />
                        </v-menu>
                      </v-flex>
                      <v-flex
                        xs12
                        md3>
                        <v-select
                          v-model="userSearch.actionValue"
                          :items="transactionTypeList"
                          :label="$t('history.action')"
                          clearable
                        >
                          <template
                            slot="selection"
                            slot-scope="data"
                            chips
                          >
                            {{ $t(data.item.text) }}
                          </template>
                          <template
                            slot="item"
                            slot-scope="data"
                          >
                            {{ $t(data.item.text) }}
                          </template>
                        </v-select>
                      </v-flex>
                    </v-layout>
                    <v-btn
                      style="margin-right: 0;"
                      class="pull-right"
                      color="success"
                      @click="onSearch($event)"
                    >
                      <span class="btn-label mr-3">
                        <i class="glyphicon glyphicon-search" />
                      </span>
                      <span style="text-transform: none;">{{
                        $t("common.search")
                      }}</span>
                    </v-btn>
                  </v-container>
                </v-form>
              </material-card>
              <material-card
                :title="$t('history.transactionHistory.title')"
                color="green"
                flat
                full-width
              >
                <v-data-table
                  v-if="hasAction('view_user') || isAdmin() || isSuperAdmin() || isModerator() || isAccountant()"
                  :no-data-text="$t('common.noDataAvailable')"
                  :headers="userHeaders"
                  :items="transactionHistoryList"
                  :single-select="true"
                  hide-actions
                >
                  <template
                    slot="headerCell"
                    slot-scope="{ header }"
                  >
                    <span
                      class="text--darken-3 font-medium"
                      v-text="$t(header.text)"
                    />
                  </template>
                  <template
                    slot="items"
                    slot-scope="{ item }"
                  >
                    <td>{{ item.stt }}</td>
                    <td
                      class="cursor-pointer"
                      @mouseover="onHoverEmail(item)"
                      @mouseleave="onLeaveEmail(item)"
                    >
                      <span
                        class="pb-1">{{ item.dateAction }}</span>
                    </td>
                    <td>{{ item.personAction }}</td>
                    <td>{{ item.customerName }}</td>
                    <td>{{ item.customerEmail }}</td>
                    <td>
                      {{ $t(getActionType(item.action).text) }}
                    </td>
                    <td
                      :style="`color: ${ getActionType(item.action).color }`"
                      class="font-weight-bold">
                      {{ item.points !== null ? item.action === transactionType.CHARGE_POINTS ? '+' : '-' : '' }}
                      {{ item.points }}
                    </td>
                    <td style="width: 15%;">
                      {{ getDescription(item.action, item.productData) }}
                    </td>
                  </template>
                </v-data-table>
              </material-card>
            </v-flex>
          </v-layout>
        </v-form>
        <pagination
          v-if="hasAction('view_user') || isAdmin() || isSuperAdmin() || isModerator() || isAccountant()"
          :total="userPaginate.totalPage"
          :current-page="userPaginate.currentPage"
          :row-per-page="userPaginate.rowPerPage"
          @onPageChange="onPageChange"
        />
        <snack-bar
          v-for="snackbar in GET_SNACK_BAR"
          :key="snackbar.id"
          :item="snackbar"/>
        <loadingBar :is-loading="isLoading" />
      </v-container>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
import stringUtils from 'utils/stringUtils'
import RoleType from 'enum/roleType'
import TransactionType from 'enum/transactionType'
import moment from 'moment'
export default {
  data: () => ({
    isShowModal: false,
    userHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      // {
      //   sortable: false,
      //   text: 'common.logo',
      //   value: 'avatar'
      // },
      {
        sortable: false,
        text: 'history.dateAction',
        value: 'dateAction'
      },
      {
        sortable: false,
        text: 'history.personAction',
        value: 'personAction'
      },
      {
        sortable: false,
        text: 'history.customerName',
        value: 'customerName'
      },
      {
        sortable: false,
        text: 'history.customerEmail',
        value: 'customerEmail'
      },
      {
        sortable: false,
        text: 'history.action',
        value: 'action'
      },
      {
        sortable: false,
        text: 'users.points',
        value: 'points'
      },
      {
        sortable: false,
        text: 'history.description',
        value: 'description'
      }
    ],
    transactionHistoryList: [],
    userPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    menu: false,
    userSearch: {
      searchText: null,
      customerType: null,
      actionValue: null
    },
    dateSearch: null,
    dateFormatted: null,
    isLoading: false,
    transactionType: TransactionType,
    transactionTypeList: stringUtils.transactionTypeList,
    customerId: null,
    isManagement: false
  }),
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.dateSearch)
    },
    ...mapGetters(['GET_SNACK_BAR', 'GET_TRANSACTION_HISTORY_LIST_DATA', 'GET_CURRENT_ACCOUNT'])
  },
  watch: {
    dateSearch (val) {
      this.dateFormatted = this.formatDate(this.dateSearch)
    },
    GET_TRANSACTION_HISTORY_LIST_DATA () {
      this.userPaginate.currentPage = this.GET_TRANSACTION_HISTORY_LIST_DATA.paginate.currentPage
      this.userPaginate.totalPage = this.GET_TRANSACTION_HISTORY_LIST_DATA.paginate.totalPage
      this.userPaginate.rowPerPage = this.GET_TRANSACTION_HISTORY_LIST_DATA.paginate.perPage
      let transactionHistoryList = this.GET_TRANSACTION_HISTORY_LIST_DATA.results
      this.transactionHistoryList = []
      for (let i = 0, size = transactionHistoryList.length; i < size; i++) {
        let userObj = {
          stt: i + 1 + (this.GET_TRANSACTION_HISTORY_LIST_DATA.paginate.currentPage - 1) * this.GET_TRANSACTION_HISTORY_LIST_DATA.paginate.pageSize,
          // stt: i + 1,
          id: transactionHistoryList[i].id,
          dateAction: dateUtils.formatDate(transactionHistoryList[i].date_created, dateUtils.STATIC_FORMAT_DATE_TIME_ZONE, 'HH:mm DD/MM/YYYY'),
          personAction: functionUtils.convertFullName(transactionHistoryList[i].user_create.last_name, transactionHistoryList[i].user_create.first_name),
          customerName: functionUtils.convertFullName(transactionHistoryList[i].user.last_name, transactionHistoryList[i].user.first_name),
          customerEmail: transactionHistoryList[i].user.email,
          action: transactionHistoryList[i].transaction_type,
          points: parseInt(transactionHistoryList[i].changed_points),
          productData: transactionHistoryList[i].product,
          isLoading: false
        }
        this.transactionHistoryList.push(userObj)
      }
      this.isLoading = false
    }
  },
  created () {},
  methods: {
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    // on show modal
    onShowModal: function (userId, isManagement) {
      this.customerId = userId
      this.userSearch.searchText = null
      this.userSearch.actionValue = null
      this.dateFormatted = null
      this.isManagement = isManagement
      this.getTransactionHistory()
      this.isShowModal = true
    },
    /**
     * Hover email
     */
    onHoverEmail: function (userItem) {
      userItem.isShowTool = true
    },
    /**
     * Leave email
     */
    onLeaveEmail: function (userItem) {
      userItem.isShowTool = false
    },
    /**
     * Format date
     */
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    /**
     * Parse date
     */
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Get system role
     */
    getSystemRole: function (roles) {
      let roleStr = ''
      for (let i = 0, size = roles.length; i < size; i++) {
        roleStr += roles[i].name + (i + 1 < size ? ', ' : '')
      }
      return roleStr
    },
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.hasSystemRole(RoleType.ADMIN, this.GET_CURRENT_ACCOUNT)
    },
    isModerator: function () {
      return functionUtils.hasSystemRole(RoleType.MODERATOR, this.GET_CURRENT_ACCOUNT)
    },
    // Check Accountant
    isAccountant: function () {
      return functionUtils.hasSystemRole(RoleType.ACCOUNTANT, this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Search
     */
    onSearch: function (event) {
      event.preventDefault()
      this.getTransactionHistory()
    },
    /**
     * Has action
     */
    hasAction: function (metaCode) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaCode)
    },
    /**
     * Get date of birth
     */
    getDateOfBirth: function (dob) {
      if (!functionUtils.isNull(dob)) {
        return dateUtils.formatDate(dob, dateUtils.STATIC_FORMAT_DATE_TIME_ZONE, dateUtils.STATIC_FORMAT_DATE)
      }
      return null
    },
    /**
     * get Action Type
     */
    getActionType: function (value) {
      if (value === TransactionType.CHARGE_POINTS) {
        return { text: 'history.transactionHistory.transactionType.addPoints', color: '#26BA50' }
      } else if (value === TransactionType.USE_POINTS) {
        return { text: 'history.transactionHistory.transactionType.userPoints', color: '#F2404D' }
      } else if (value === TransactionType.REFUND_POINTS) {
        return { text: 'history.transactionHistory.transactionType.refundPoints', color: '#F2404D' }
      } else {
        return { text: '', color: '' }
      }
    },
    /**
     * get description
     */
    getDescription: function (actionType, productData) {
      let results = ''
      switch (actionType) {
        case TransactionType.USE_POINTS:
          if (productData.id) {
            results = `${productData.name} - ${productData.code}`
          }
          break
        case TransactionType.CHARGE_POINTS:
          break
        default:
          return results
      }
      return results
    },
    /**
     * Get user list
     */
    getTransactionHistory: function () {
      this.isLoading = true
      let filter = {
        params: {
          page: this.userPaginate.currentPage,
          searchText: this.userSearch.searchText,
          action_value: this.userSearch.actionValue
        }
      }
      if (this.isManagement) {
        filter.params['management_id'] = this.customerId
      } else {
        filter.params['customer_id'] = this.customerId
      }
      let toFormat = 'YYYY-MM-DD HH:mm:ss'
      if (!functionUtils.validateStringIsNull(this.dateFormatted)) {
        let startDate = moment(this.dateFormatted, 'DD/MM/YYYY')
        let endDate = moment(this.dateFormatted, 'DD/MM/YYYY')
        filter.params['start_date'] = this.getStartOfDayUTC(startDate, toFormat)
        filter.params['end_date'] = this.getEndOfDayUTC(endDate, toFormat)
      }
      this.GET_TRANSACTION_HISTORY_LIST(filter).then(
        function () {}
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    getStartOfDayUTC: function (dateValue, toFormat) {
      return dateValue.startOf('day').utc().format(toFormat)
    },
    getEndOfDayUTC: function (dateValue, toFormat) {
      return dateValue.endOf('day').utc().format(toFormat)
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.userPaginate.currentPage = page
      this.getTransactionHistory()
    },
    ...mapActions(['GET_TRANSACTION_HISTORY_LIST', 'UPDATE_USER_PROFILE', 'DELETE_USER', 'ON_SHOW_TOAST', 'ACTIVE_USER', 'SEND_EMAIL_FORGOT_PASSWORD'])
  }
}
</script>
<style lang="scss" scoped>
@import "../../styles/material-dashboard/_colors.scss";
/deep/ .test-primary {
  background-color: #c7c7c7c7 !important;
}
/deep/ .test-primary:hover {
  background-color: #c7c7c7c7 !important;
}
/deep/ .theme--light.v-list {
  background-color: transparent;
}
.border-style {
  border-bottom: 2px solid $border-color;
}
.cursor-pointer {
  cursor: pointer !important;
}
.hover-detail-text:hover {
  border-bottom: 1px solid $border-color;
}
.hover-delete-text:hover {
  border-bottom: 1px solid $border-color;
}
.hover-active-text:hover {
  border-bottom: 1px solid $border-color;
}
// .modal {
//   box-shadow: 2px 2px 20px 1px;
//   overflow-x: auto;
//   display: flex;
//   flex-direction: column;
//   overflow-y: auto;
// }
// .v-card {
//   margin-bottom: 0px !important;
//   margin-top: 21px !important;
// }
// /deep/.required label::after {
//   content: " *";
//   color: red;
// }
</style>

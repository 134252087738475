<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="$t('users.addPoints')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout wrap>
              <v-flex
                xs12
                sm12
                md12>
                <p v-if="fullName && !code"> {{ $t('users.chargePointsModalContent') }}
                  <span class="font-weight-bold"> {{ fullName }} </span>
                  <span> ( {{ email }} )</span>
                </p>
                <p v-else-if="prepaidCardIds.length > 0">{{ $t('prepaidCard.rechargePointsFor') }} <span class="font-weight-bold">{{ prepaidCardIds.length }}</span> {{ $t('prepaidCard.prepaidCard') }}</p>
                <p v-else>{{ $t('prepaidCard.rechargePointForCardCode') }} <span class="font-weight-bold">{{ code }}</span></p>
              </v-flex>
              <v-flex
                xs12
                sm8
                md6>
                <v-text-field
                  :rules="[requiredValue, isGreaterThanZero]"
                  v-model="currencyFormat"
                  :label="$t('users.depositAmount') + ' (VND)'"
                  :name="$t('users.depositAmount')"
                  prepend-icon="mdi-bitcoin"
                  type="text"
                  class="required"
                  @input="changeCurrencyToPoints"
                />
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <p> {{ $t('users.numOfExchangePoint') }}
                <span class="font-weight-bold"> {{ points }} </span>
                <span v-if="!validateStringIsNull(points)"> ( {{ $t('users.points') }} )</span>
              </p>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onShowModalConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onConfirm"
    />
  </v-dialog>
</template>
<script>
import functionUtils from 'utils/functionUtils'
import ToastType from 'enum/toastType'
import ConfirmModal from 'Components/ConfirmModal'
import { mapActions } from 'vuex'
import NotificationAction from 'enum/notificationAction'
import customerType from 'enum/CustomerType'
export default {
  components: {
    ConfirmModal
  },
  data () {
    return {
      valid: false,
      isShowModal: false,
      userId: null,
      fullName: null,
      phone: null,
      menu: false,
      currentPage: 1,
      email: null,
      points: null,
      isLoading: false,
      currency: null,
      currencyFormat: null,
      exchangeRate: null,
      confirmModalTitle: '',
      code: null,
      prepaidCardIds: [],
      type: null,
      customerType: customerType,
      nameCard: null
    }
  },
  created () {
    this.GET_EXCHANGE_RATE()
      .then(
        function (res) {
          let data = res.data
          this.exchangeRate = data.exchange_rate
          this.isLoading = false
        }.bind(this)
      )
      .catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
  },
  methods: {
    validateStringIsNull: function (value) {
      return functionUtils.validateStringIsNull(value)
    },
    /**
     * Required value
     */
    requiredValue (value) {
      if (value !== null && value !== undefined && value.length !== 0) {
        return true
      }
      return this.$t('common.requiredFields')
    },
    isGreaterThanZero (value) {
      value = functionUtils.formatInteger(value)
      if (value > 0) {
        return true
      }
      return this.$t('users.valueMustBeGreaterThanZero')
    },
    onShowModal: function (userData, currentPage, prepaidCardIds) {
      this.setDefaultData()
      this.currentPage = currentPage
      if (userData) {
        this.userId = userData && userData.id
        this.fullName = userData && userData.fullname
        this.phone = userData && userData.phone
        this.email = userData && userData.email
        this.code = userData && userData.code
      } else {
        this.prepaidCardIds = prepaidCardIds
      }
      this.isShowModal = true
      this.points = null
      this.currencyFormat = null
      this.currency = null
      this.type = userData.type
      this.nameCard = userData && userData.name
    },
    convertFormatNumberToMoney: function (value) {
      value = functionUtils.formatInteger(value && value.toString())
      this.currencyFormat = functionUtils.convertFormatNumberToMoney(value)
      this.currency = value
    },
    changeCurrencyToPoints: function (value) {
      this.convertFormatNumberToMoney(value)
      value = functionUtils.formatInteger(value && value.toString())
      if (!functionUtils.validateStringIsNull(value) && value > 0) {
        this.points = value / this.exchangeRate
      } else {
        this.points = null
      }
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    /**
     * Set default data
     */
    setDefaultData: function () {
      this.userId = null
      this.fullName = null
      this.phone = null
      this.email = null
      this.code = null
      this.prepaidCardIds = []
    },
    onShowModalConfirm: function () {
      if (functionUtils.validateStringIsNull(this.points)) {
        this.ON_SHOW_TOAST({
          'message': this.$t('users.depositAmount'),
          'styleType': ToastType.ERROR
        })
      } else {
        this.confirmModalTitle = this.$t('users.confirmChargePointsTitle', { '0': this.type === this.customerType.CUSTOMER ? this.fullName : this.nameCard })
        this.$refs.confirmModal.onShowModal()
      }
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      if (functionUtils.validateStringIsNull(this.points)) {
        this.ON_SHOW_TOAST({
          'message': this.$t('users.depositAmount'),
          'styleType': ToastType.ERROR
        })
      } else {
        let data = {
          user_ids: this.prepaidCardIds.length > 0 ? this.prepaidCardIds : [this.userId],
          points: this.points
        }
        this.POST_CHARGE_POINT(data).then(
          function (res) {
            this.isShowModal = false
            this.$emit('updatePoint', true)
            this.ON_SHOW_TOAST({
              'message': this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            let notificationData = {}
            if (this.type === this.customerType.CUSTOMER) {
              let chargedPoints = res.data.points !== undefined ? res.data.points : ''
              let addPointsCustomer = {
                // message_title: this.$t('notification.customer.rechargedPointsTitle'),
                // message_body: this.$t('notification.customer.rechargedPoints', { '0': this.points, '1': chargedPoints }),
                user_id: this.userId,
                actionValue: NotificationAction.ADD_POINTS,
                points: this.points,
                total_points: chargedPoints
              }
              notificationData = addPointsCustomer
            }
            if (this.type === this.customerType.USER_GHOST) {
              let chargedPoints = res.data.points !== undefined ? res.data.points : ''
              let addPointsCustomer = {
                code_card: this.code,
                name_card: this.nameCard,
                actionValue: NotificationAction.ADD_POINTS_PREPAID_CARD,
                points: this.points,
                total_points: chargedPoints
              }
              notificationData = addPointsCustomer
            }

            this.PUSH_NOTIFICATION(notificationData)
          }.bind(this)
        ).catch(
          function (error) {
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                'message': this.$t(`${errorText}`),
                styletype: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                'message': this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
          }.bind(this)
        )
      }
    },
    ...mapActions(['POST_CHARGE_POINT', 'GET_EXCHANGE_RATE', 'ON_SHOW_TOAST', 'PUSH_NOTIFICATION'])
  }

}
</script>
<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>

<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="$t('users.refundPoints')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout wrap>
              <v-flex
                xs12
                sm12
                md12>
                <p v-if="type === customerType.CUSTOMER"> {{ $t('users.customerRefundPoint.customerInfo') }}
                  <span class="font-weight-bold"> {{ fullName }} </span>
                  <span> ( {{ email }} )</span>
                  <span> {{ $t('users.customerRefundPoint.customerCurrentPoint') }} </span>
                  <span class="font-weight-bold"> {{ points }} {{ $t('users.points') }}</span>
                </p>
                <p v-else>{{ $t('users.prepaidCardRefundPoint.prepaidCardInfo') }}
                  <span class="font-weight-bold"> {{ fullName }} </span>
                  <span> ( {{ code }} )</span>
                  <span> {{ $t('users.prepaidCardRefundPoint.prepaidCardCurrentPoint') }} </span>
                  <span class="font-weight-bold"> {{ points }} {{ $t('users.points') }}</span>
                </p>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex
                xs12
                sm12
                md12>
                <span>{{ $t('users.exchangedMoney') }}: </span><span class="font-weight-bold">{{ getCurrency(points) }} {{ $t('common.vnd') }}</span>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex
                xs12
                sm12
                md12>
                <p v-if="fullName && !code"> {{ $t('users.customerRefundPoint.confirmCustomerRefundPointTitle', {'0': fullName}) }}</p>
                <p v-else> {{ $t('users.prepaidCardRefundPoint.confirmPrepaidCardRefundPointTitle', {'0': fullName}) }} </p>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import functionUtils from 'utils/functionUtils'
import ToastType from 'enum/toastType'
import { mapActions } from 'vuex'
import NotificationAction from 'enum/notificationAction'
import customerType from 'enum/CustomerType'
export default {
  components: {},
  data () {
    return {
      valid: false,
      isShowModal: false,
      userId: null,
      fullName: null,
      phone: null,
      menu: false,
      currentPage: 1,
      email: null,
      points: null,
      isLoading: false,
      currency: null,
      currencyFormat: null,
      exchangeRate: null,
      confirmModalTitle: '',
      code: null,
      type: null,
      customerType: customerType
    }
  },
  created () {
    this.GET_EXCHANGE_RATE()
      .then(
        function (res) {
          let data = res.data
          this.exchangeRate = data.exchange_rate
          this.isLoading = false
        }.bind(this)
      )
      .catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
  },
  methods: {
    isGreaterThanZero (value) {
      value = functionUtils.formatInteger(value)
      if (value > 0) {
        return true
      }
      return this.$t('users.valueMustBeGreaterThanZero')
    },
    getCurrency: function (points) {
      let value = this.exchangeRate * points
      return this.convertFormatNumberToMoney(value)
    },
    convertFormatNumberToMoney: function (value) {
      value = functionUtils.formatInteger(value && value.toString())
      let currencyFormat = functionUtils.convertFormatNumberToMoney(value)
      return currencyFormat
    },
    onShowModal: function (userData, currentPage) {
      this.currentPage = currentPage
      this.userId = userData && userData.id
      this.fullName = userData && userData.fullname !== undefined ? userData.fullname : userData.name
      this.phone = userData && userData.phone
      this.email = userData && userData.email
      this.code = userData && userData.code
      this.isShowModal = true
      this.points = userData.points
      this.currencyFormat = null
      this.currency = null
      this.type = userData.type
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      let data = {
        user_id: this.userId
      }
      this.POST_REFUND_POINT(data).then(
        function (res) {
          this.isShowModal = false
          this.$emit('updatePoint', true)
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          let totalPoints = res.data.points !== undefined ? res.data.points : ''
          let notificationData = {}
          if (this.type === this.customerType.CUSTOMER) {
            let refundPointsCustomer = {
              user_id: this.userId,
              actionValue: NotificationAction.REFUND_POINTS_FOR_CUSTOMER,
              refund_points: this.points,
              total_points: totalPoints
            }
            notificationData = refundPointsCustomer
          }
          if (this.type === this.customerType.USER_GHOST) {
            let addPointsPrepaidCard = {
              code_card: this.code,
              name_card: this.fullName,
              actionValue: NotificationAction.REFUND_POINTS_PREPAID_CARD,
              total_points: totalPoints,
              refund_points: this.points
            }
            notificationData = addPointsPrepaidCard
          }
          this.PUSH_NOTIFICATION(notificationData)
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              'message': this.$t(`${errorText}`),
              styletype: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              'message': this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    ...mapActions(['POST_REFUND_POINT', 'ON_SHOW_TOAST', 'PUSH_NOTIFICATION', 'GET_EXCHANGE_RATE'])
  }

}
</script>
<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>

const NotificationAction = {
  ADD_POINTS: 1,
  CUSTOMER_USE_POINT: 2,
  STAFF_CONFIRM_CUSTOMER_USE_POINT: 3,
  REFUND_POINTS_FOR_CUSTOMER: 4,
  ADD_POINTS_PREPAID_CARD: 5,
  REFUND_POINTS_PREPAID_CARD: 6,
  STAFF_CONFIRM_PREPAID_CARD_USE_POINT: 7
}
export default NotificationAction
